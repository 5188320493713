import { Schemas } from '~/api/gen'
import { Img } from '~/components/elements/Img'
import { MemberBadge } from '../MemberBadge'

type Props = {
  members: Schemas.member_small[]
}
export const MemberCarouselList: React.FC<Props> = ({ members }) => {
  return (
    <ul className="ml-[-25px] flex gap-[15px] overflow-x-auto px-[25px] pt-[9px] hide-scroll xl:gap-4 xl:overflow-x-visible">
      {members.map((member) => {
        return (
          <li key={member.id} className="w-[90px] shrink-0 xl:w-[97px]">
            <a
              href={`/${member.user_name}/`}
              className="flex flex-col gap-[7px] text-center xl:gap-[5px]"
            >
              <div className="overflow-hidden rounded-full shadow-[0_0_15px_0_rgb(0,0,0,.2)] xl:border xl:shadow-none xl:hover:opacity-70">
                <Img
                  src={member.image.url_120}
                  width={120}
                  height={120}
                  alt=""
                  className="h-auto w-full"
                />
              </div>
              <div className="flex justify-center gap-1">
                <p className="truncate text-[14px]">{member.nick_name}</p>
                <MemberBadge
                  member={member}
                  className="inline-block size-4 shrink-0 pt-1 xl:size-[18px]"
                />
              </div>
            </a>
          </li>
        )
      })}
    </ul>
  )
}
