import { GetServerSideProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import { client } from '~/api/client'
import {
  getOfficialRecommendedMembersV1,
  Schemas,
  getCoordinateHourlyRankingsV1,
  getWebPickupTagSchedulesRecentlyV1,
  getMembersUserNameFoldersV1,
} from '~/api/gen'
import { Layout } from '~/components/Layout'
import { Meta } from '~/components/Meta'
import { sendGTMEventHandler } from '~/components/modules/DownloadHeader'
import { SpTopPage, Props } from '~/features/SpTopPage/SpTopPage'
import { JP_COUNTRY_ID } from '~/features/shared/country'
import { useBaseUrl } from '~/features/shared/domain/context'
import { TOP_PAGE_JSON_LD } from '~/features/shared/topPage/lib/topPageJsonLd'
import { TOP_PAGE_HEADER_ADJUST_URL } from '~/lib/constants'
import { genderToSexId } from '~/lib/gender'
import { getUA } from '~/lib/getUA'

const GoogleAdScripts = dynamic(
  () => import('~/features/shared/ad/components/GoogleAdScripts'),
  {
    ssr: false,
  }
)

// 4日分×MEN、WOMEN、KIDSの3性別で12
// https://github.com/st-tech/wear2-server/pull/13908#issue-2479695750
const PICKUP_TAG_SCHEDULES_COUNT = 12
const PICKUP_TAG_COORDINATES_COUNT = 3

const WEAR_OFFICIAL_USER_NAME = 'wearofficial'
const FOLDER_FETCH_PAGE_NO = 1
const FOLDER_FETCH_COUNT_PER_PAGE = 3

const POPULAR_USER_COUNT = 30
const COORDINATE_RANKING_COUNT = 3

const TopPage: NextPage<Props> = ({
  pickupTags,
  popularUsers,
  popularFolders,
  coordinateRanking,
}) => {
  const baseUrl = useBaseUrl()

  return (
    <Layout
      breadcrumbList={[]}
      appUrl="wear2020://wear.jp"
      abstractUrl="wear.jp"
      donwloadHeaderType="temporary"
      appDownloadUrl={TOP_PAGE_HEADER_ADJUST_URL}
      onClickAppLink={sendGTMEventHandler}
    >
      <Meta
        title="ファッションコーディネート"
        description="ZOZOが運営する日本最大級のファッションコーディネートサイト「WEAR（ウェア）」。参考になるおしゃれな服装や、今すぐほしいトレンドアイテムがたくさん！1400万枚以上の中からコーデが探せます。気に入ったアイテムはそのまま購入も！"
        url={baseUrl.href}
        appUrl="wear2020://wear.jp/"
        jsonLdList={[TOP_PAGE_JSON_LD]}
      />
      <GoogleAdScripts pages={[{ label: 'sp:top' }]} />
      <SpTopPage
        pickupTags={pickupTags}
        popularUsers={popularUsers}
        popularFolders={popularFolders}
        coordinateRanking={coordinateRanking}
      />
    </Layout>
  )
}

// eslint-disable-next-line complexity
export const getServerSideProps: GetServerSideProps<Props> = async ({
  req,
}) => {
  const isSP = getUA(req.headers['user-agent'] ?? '').isSP

  if (!isSP) {
    return {
      redirect: {
        statusCode: 302,
        destination: '/',
      },
    }
  }

  const [
    pickupTagResponse,
    popularUsersResponse,
    foldersResponse,
    menCoordinateRankingResponse,
    womenCoordinateRankingResponse,
    kidsCoordinateRankingResponse,
  ] = await Promise.allSettled([
    getWebPickupTagSchedulesRecentlyV1(client, {
      parameter: {
        pickup_tag_schedules_count: PICKUP_TAG_SCHEDULES_COUNT,
        coordinates_count: PICKUP_TAG_COORDINATES_COUNT,
      },
    }),
    getOfficialRecommendedMembersV1(client, {
      parameter: {
        count: POPULAR_USER_COUNT,
        country_id: JP_COUNTRY_ID,
      },
    }),
    getMembersUserNameFoldersV1(client, {
      parameter: {
        user_name: WEAR_OFFICIAL_USER_NAME,
        pageno: FOLDER_FETCH_PAGE_NO,
        pagesize: FOLDER_FETCH_COUNT_PER_PAGE,
      },
    }),
    getCoordinateHourlyRankingsV1(client, {
      parameter: {
        count: COORDINATE_RANKING_COUNT,
        ranking_type: genderToSexId('men'),
        country_id: JP_COUNTRY_ID,
      },
    }),
    getCoordinateHourlyRankingsV1(client, {
      parameter: {
        count: COORDINATE_RANKING_COUNT,
        ranking_type: genderToSexId('women'),
        country_id: JP_COUNTRY_ID,
      },
    }),
    getCoordinateHourlyRankingsV1(client, {
      parameter: {
        count: COORDINATE_RANKING_COUNT,
        ranking_type: genderToSexId('kids'),
        country_id: JP_COUNTRY_ID,
      },
    }),
  ])

  if (pickupTagResponse.status === 'rejected') {
    throw new Error(`Failed to fetch piciupTag: ${pickupTagResponse.reason}`)
  }
  if (pickupTagResponse.value.type === 'error')
    throw new Error(
      `Failed to fetch coordiante: ${pickupTagResponse.value.raw.statusText}`
    )

  const pickupTags = pickupTagResponse.value.data.pickup_tag_schedules

  const popularFolders: Schemas.folder_collection_with_folder_elements[] =
    foldersResponse.status === 'fulfilled' &&
    foldersResponse.value.type === 'success'
      ? foldersResponse.value.data.folders
      : []

  const popularUsers: Schemas.official_recommended_member_with_member[] =
    popularUsersResponse.status === 'fulfilled' &&
    popularUsersResponse.value.type === 'success'
      ? popularUsersResponse.value.data.official_recommended_members
      : []

  const menCoordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[] =
    menCoordinateRankingResponse.status === 'fulfilled' &&
    menCoordinateRankingResponse.value.type === 'success'
      ? menCoordinateRankingResponse.value.data.coordinate_hourly_rankings
      : []

  const womenCoordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[] =
    womenCoordinateRankingResponse.status === 'fulfilled' &&
    womenCoordinateRankingResponse.value.type === 'success'
      ? womenCoordinateRankingResponse.value.data.coordinate_hourly_rankings
      : []

  const kidsCoordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[] =
    kidsCoordinateRankingResponse.status === 'fulfilled' &&
    kidsCoordinateRankingResponse.value.type === 'success'
      ? kidsCoordinateRankingResponse.value.data.coordinate_hourly_rankings
      : []

  return {
    props: {
      pickupTags,
      popularUsers,
      popularFolders,
      coordinateRanking: [
        ...menCoordinateRanking,
        ...womenCoordinateRanking,
        ...kidsCoordinateRanking,
      ],
    },
  }
}

export default TopPage
