import { Img } from '~/components/elements/Img'

type Props = {
  imgUrls: string[]
  href: string
  title: string
}

export const PopularFolder: React.FC<Props> = ({ imgUrls, href, title }) => {
  return (
    <div className="overflow-hidden rounded-[7px] bg-white shadow-[0_0_15px_0px_rgba(0,0,0,.2)]">
      <a href={href}>
        <div className="grid grid-flow-col grid-cols-6 grid-rows-2 gap-[1px] [&>*:nth-child(3n+1)]:col-span-2 [&>*:nth-child(3n+1)]:row-span-2">
          {[...Array(6)].map((_, index) => {
            const url = imgUrls[index]
            if (!url) return <span className="bg-gray-50" key={index} />
            return (
              <Img
                src={url}
                alt=""
                width={114}
                height={153}
                key={url}
                className="size-full object-cover"
              />
            )
          })}
        </div>
        <div className="px-[15px] pb-3 pt-[14px]">
          <h3 className="text-[14px] font-bold leading-[1.4]">{title}</h3>
        </div>
      </a>
    </div>
  )
}
