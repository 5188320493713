import { Divider } from '~/components/elements/Divider'
import { Img } from '~/components/elements/Img'
import { useAbstractUrl } from '~/features/shared/abstractUrl/hooks/useAbstractUrl'
import { useBanner } from '~/features/shared/banner/hooks/useBanner'
import { Banner } from '~/features/shared/banner/types'

export const BannersView: React.FC<{
  banners: Banner[]
  onClickBanner: (banner: Banner) => Promise<void>
}> = ({ banners, onClickBanner }) => {
  return (
    <div className="flex flex-col items-center gap-y-[33px]">
      <Divider className="w-[70px]" />
      <ul className="flex flex-col gap-y-[15px]">
        {banners.map((banner) => {
          return (
            <li key={banner.id} className="flex">
              <button type="button" onClick={() => onClickBanner(banner)}>
                <Img
                  src={banner.image_url}
                  alt={banner.image_alt_attribute || banner.title}
                  width={345}
                  height={133}
                  className="w-full"
                />
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export const Banners: React.FC = () => {
  const abstractUrl = useAbstractUrl()
  const { banners, onClickBanner } = useBanner('sp_top_ftr', abstractUrl)
  if (banners.length === 0) return null

  return <BannersView banners={banners} onClickBanner={onClickBanner} />
}
