import { RefCallback, useCallback, useEffect, useRef, useState } from 'react'

/**
 * 要素の重なりを検証するフック (IntersectionObserver)
 * @param options IntersectionObserverの第二引数(margin等)
 * @returns 交差しているか検証したい要素へのref / 交差しているか のタプル
 */
export const useIntersection = (
  options?: IntersectionObserverInit
): readonly [RefCallback<Element | null>, boolean] => {
  const target = useRef<Element | null>(null)
  const observer = useRef<IntersectionObserver | undefined>()
  const [intersecting, setIntersecting] = useState(false)
  const handler = useCallback<IntersectionObserverCallback>(([entry]) => {
    if (entry === undefined) return
    setIntersecting(entry.isIntersecting)
  }, [])

  useEffect(() => {
    observer.current = new IntersectionObserver(handler, {
      root: options?.root,
      rootMargin: options?.rootMargin,
      threshold: options?.threshold,
    })
    if (target.current) observer.current.observe(target.current)
    return () => {
      observer.current?.disconnect()
      observer.current = undefined
    }
  }, [handler, options?.root, options?.rootMargin, options?.threshold])

  const ref = useCallback((node: Element | null) => {
    observer.current?.disconnect()
    if (node == null) return
    target.current = node
    observer.current?.observe(node)
  }, [])

  return [ref, intersecting]
}
