import { Img } from '~/components/elements/Img'
import { ArrowRightIcon } from '~/components/icons/generated/ArrowRightIcon'
import { GenderProfile } from '~/lib/gender'

type Props = {
  tagName: string
  tagHref: string
  gender: GenderProfile
  count: number
  nickName: string
  height: number
  coordinateHref: string
  imgUrl: string
  imgAlt: string
  isPR?: boolean
  imagePriority?: boolean
}

export const PickupTagLargeCard: React.FC<Props> = ({
  tagName,
  tagHref,
  gender,
  count,
  nickName,
  height,
  coordinateHref,
  imgUrl,
  imgAlt,
  isPR = false,
  imagePriority = false,
}) => {
  return (
    <div className="relative overflow-hidden rounded-[10px] leading-none text-white shadow-[0_0_30px_0px_rgba(0,0,0,.5)] before:absolute before:size-full before:bg-black/40 before:content-['']">
      <Img
        width={345}
        height={460}
        src={imgUrl}
        alt={imgAlt}
        className="w-full"
        priority={imagePriority}
      />
      <div className="absolute top-0 flex size-full flex-col items-center justify-center pt-[3px]">
        <p className="text-[13px]">ピックアップタグ</p>
        <h2
          className={
            isPR
              ? 'flex flex-col items-center gap-y-[5px] pt-4 before:rounded-[4px] before:bg-white/20 before:px-[11px] before:py-[3px] before:text-[10px] before:leading-none before:content-["PR"]'
              : 'pt-3'
          }
        >
          <a
            href={tagHref}
            className="border-b-2 border-white text-[32px] font-bold leading-[1.2]"
          >
            {`#${tagName}`}
          </a>
        </h2>
        <p className="pt-[17px] text-[13px]">
          （{gender}・{count.toLocaleString()}件）
        </p>
      </div>
      <p className="absolute bottom-[13px] right-3">
        <a href={coordinateHref} className="flex gap-[2px] text-[12px]">
          {nickName}&nbsp;
          {height}cm
          <ArrowRightIcon className="size-3" />
        </a>
      </p>
    </div>
  )
}
