import { Img } from '~/components/elements/Img'
import { WEARAppIconWithoutBorder } from '~/components/icons/generated/WEARAppIconWithoutBorder'
import mockImg from './mock.webp'

type Props = {
  downloadButtonHref: string
  onClickDownloadButton?: () => void
}

export const SpAppBanner: React.FC<Props> = ({
  downloadButtonHref,
  onClickDownloadButton,
}) => {
  return (
    <section className="flex flex-col items-center gap-y-6 bg-gray-50 pb-8 pt-10 leading-[1.4]">
      <WEARAppIconWithoutBorder
        width={68}
        height={68}
        className="drop-shadow-[0_0_8px_rgba(0,0,0,0.06)]"
      />
      <div className="flex flex-col items-center gap-y-3">
        <h4 className="text-[18px] font-bold">
          WEARアプリ版が大幅リニューアル
        </h4>
        <p className="text-center font-[12px]">
          ファッションジャンル診断やメイク試着も！
          <br />
          新しいWEARをお試しください。
        </p>
      </div>
      <Img src={mockImg} alt="" width="374" height="178" />
      <div className="flex flex-col items-center gap-y-3">
        <a
          className="flex h-11 items-center justify-center rounded-full bg-blue-400 px-12 text-[14px] font-bold leading-none text-white"
          href={downloadButtonHref}
          target="_blank"
          onClick={onClickDownloadButton}
          rel="noreferrer"
        >
          ダウンロードはこちら
        </a>
        <a href="/first/" className="text-[14px] font-bold text-blue-400">
          アプリについて
        </a>
      </div>
    </section>
  )
}
