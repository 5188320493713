import { sendGTMEvent } from '~/features/shared/gtmEvent'
import { SpTopPageEvents } from '~/features/shared/gtmEvent/types/spTopPage'
import { deviceAdapter } from '~/lib/deviceAdapter'

export const sendGtmEvDownloadButton = () => {
  const baseEventData = {
    event: 'appAd_top_f',
    eventCategory: 'link',
    pageGroup: 'top_f',
  } as const
  sendGTMEvent(
    deviceAdapter(
      {
        ios: {
          ...baseEventData,
          appstore: 'App_iOS',
        } satisfies SpTopPageEvents,
        android: {
          ...baseEventData,
          appstore: 'App_And',
        } satisfies SpTopPageEvents,
      },
      {}
    )
  )
}
