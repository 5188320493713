import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useIntersection } from '~/hooks/useIntersection'
import { GenderProfile } from '~/lib/gender'
import { PickupTagLargeCard } from './PickupTagLargeCard'

type IntersectionItemProps = {
  onMoveInViewport: () => void
}
const IntersectionItem: React.FC<PropsWithChildren<IntersectionItemProps>> = ({
  onMoveInViewport,
  children,
}) => {
  const [intersectionRef, intersecting] = useIntersection({
    threshold: 1,
  })
  const callback = useRef(onMoveInViewport)
  callback.current = onMoveInViewport
  useEffect(() => {
    if (intersecting) callback.current()
  }, [intersecting])

  return <div ref={intersectionRef}>{children}</div>
}

type Props = {
  coordinates: {
    tagName: string
    tagHref: string
    gender: GenderProfile
    count: number
    nickName: string
    height: number
    coordinateHref: string
    imgUrl: string
    imgAlt: string
    isPR?: boolean
  }[]
}

export const PickupTagCarousel: React.FC<Props> = ({ coordinates }) => {
  const intersectionRootRef = useRef(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div className="relative">
      <ul
        className="flex snap-x snap-mandatory overflow-x-scroll pb-3 hide-scroll"
        ref={intersectionRootRef}
      >
        {coordinates.map((coordinate, index) => {
          return (
            <li
              className="w-screen shrink-0 snap-center p-[15px]"
              key={coordinate.imgUrl}
            >
              <IntersectionItem onMoveInViewport={() => setCurrentIndex(index)}>
                <PickupTagLargeCard
                  {...coordinate}
                  imagePriority={index === 0}
                />
              </IntersectionItem>
            </li>
          )
        })}
      </ul>
      <div className="absolute bottom-[1px] flex w-full justify-center gap-x-[6px]">
        {coordinates.map((_, index) => {
          return (
            <span
              key={index}
              className={`h-[1px] w-[35px] ${currentIndex === index ? 'bg-black-400' : 'bg-gray-300'}`}
            />
          )
        })}
      </div>
    </div>
  )
}
