import { Img } from '~/components/elements/Img'
import { getCoordinateImageAlt } from '~/features/shared/coordinate/lib/getCoordinateImageAlt'

type Props = {
  coordinates: {
    id: number
    image: { url_276: string }
    member: { user_name: string; nick_name: string }
  }[]
}

export const CoordinateListWithRounded: React.FC<Props> = ({ coordinates }) => {
  return (
    <ul className="grid grid-cols-3 gap-3 xl:grid-cols-6 xl:gap-[19px]">
      {coordinates.map((coordinate) => {
        return (
          <li
            key={coordinate.id}
            className="rounded-[7px] shadow-folder-item xl:shadow-none xl:hover:opacity-70"
          >
            <a href={`/${coordinate.member.user_name}/${coordinate.id}/`}>
              <Img
                src={coordinate.image.url_276}
                width={276}
                height={368}
                alt={getCoordinateImageAlt({
                  nickName: coordinate.member.nick_name,
                })}
                className="h-auto w-full rounded-[7px]"
              />
            </a>
          </li>
        )
      })}
    </ul>
  )
}
