import { Img } from '~/components/elements/Img'
import { buildUrl } from '~/features/shared/search/coordinate'

type Coordinate = {
  href: string
  imgUrl: string
  imgAlt: string
}
type Props = {
  tagName: string
  tagId: number
  coordinates: Coordinate[]
  imagePriority?: boolean
}

export const PickupTagSmallCards: React.FC<Props> = ({
  tagName,
  tagId,
  coordinates,
  imagePriority = false,
}) => (
  <div className="flex flex-col gap-y-[14px] px-[15px]">
    <h3 className="flex items-center justify-between leading-none">
      <span className="truncate pr-[27px] text-[23px] font-bold">{`#${tagName}`}</span>
      <a
        href={buildUrl({ tagIds: [tagId] })}
        className="shrink-0 text-[13px] font-bold text-blue-400"
      >
        すべて見る
      </a>
    </h3>
    <ul className="grid grid-cols-3 gap-3">
      {coordinates.slice(0, 3).map((coordinate) => (
        <li
          key={coordinate.href}
          className="overflow-hidden rounded-[7px] shadow-[0_0_15px_0px_rgba(0,0,0,.2)]"
        >
          <a href={coordinate.href}>
            <Img
              src={coordinate.imgUrl}
              alt={coordinate.imgAlt}
              width={107}
              height={143}
              className="w-full"
              priority={imagePriority}
            />
          </a>
        </li>
      ))}
    </ul>
  </div>
)
