import dynamic from 'next/dynamic'
import { Schemas } from '~/api/gen'
import { TOP_PAGE_APP_BANNER_ADJUST_URL } from '~/lib/constants'
import { genderToGenderProfile, sexToGender } from '~/lib/gender'
import { SpAppBanner } from '../shared/components/SpAppBanner/SpAppBanner'
import { CoordinateListWithRounded } from '../shared/components/coordinate/CoordinateListWithRounded'
import { MemberCarouselList } from '../shared/components/member/MemberCarouselList'
import { getCoordinateImageAlt } from '../shared/coordinate/lib/getCoordinateImageAlt'
import { buildUrl } from '../shared/search/coordinate'
import { Banners } from './components/Banners'
import { PickupTagCarousel } from './components/PickupTagCarousel'
import { PickupTagSmallCards } from './components/PickupTagSmallCards'
import { PopularFolder } from './components/PopularFolder'
import { spTopPageAds } from './data/googleAd'
import { sendGtmEvDownloadButton } from './lib/gtmEvent'

const ThinBannerForSP = dynamic(
  () => import('~/features/shared/banner/SP/ThinBanner'),
  {
    ssr: false,
  }
)

const GoogleAd = dynamic(
  () => import('~/features/shared/ad/components/GoogleAd'),
  {
    ssr: false,
  }
)

export type Props = {
  pickupTags: Schemas.pickup_tag_schedule_with_coordinates[]
  popularUsers: Schemas.official_recommended_member_with_member[]
  popularFolders: Schemas.folder_collection_with_folder_elements[]
  coordinateRanking: Schemas.coordinate_hourly_ranking_with_coordinate[]
}

export const SpTopPage: React.FC<Props> = ({
  pickupTags,
  popularUsers,
  popularFolders,
  coordinateRanking,
}) => {
  // pickupTagsは
  // [Men1,Women1,Kids1,
  //  M2,W2,K2,
  //  M3,W3,K3,
  //  M4,W4,K4]の順でデータが含まれている
  // 女性、男性、KIDSの順で最新のタグを表示
  const largePickupTags = [pickupTags[1], pickupTags[0], pickupTags[2]].filter(
    (tag) => tag !== undefined
  )
  // 女性の２番目のタグ、男性の３番目のタグ、KIDSの４番目のタグを表示
  const smallPickupTags = [pickupTags[4], pickupTags[6], pickupTags[11]].filter(
    (tag) => tag !== undefined
  )

  const pickupTagCarouselCoordinates = largePickupTags
    .map(({ tag, sex_id: sexId }) => {
      const coordinate = tag.coordinates[0]
      if (!coordinate) return

      const gender = sexToGender(sexId)
      const genderProfile = genderToGenderProfile(gender)
      if (genderProfile === undefined) return
      return {
        tagName: tag.name,
        tagHref: buildUrl({ gender, tagIds: [tag.id] }),
        imgUrl: coordinate.image.url_500,
        imgAlt: getCoordinateImageAlt({
          nickName: coordinate.member.nick_name,
        }),
        gender: genderProfile,
        nickName: coordinate.member.nick_name,
        height: coordinate.member.height ?? 0,
        coordinateHref: `/${coordinate.member.user_name}/${coordinate.id}/`,
        count: tag.total_coordinate_count ?? 0,
      }
    })
    .filter((coordinate) => coordinate !== undefined)

  return (
    <div>
      <ThinBannerForSP bannerType="sp_narrow_menu_top" />
      <div className="flex flex-col gap-y-9">
        <PickupTagCarousel coordinates={pickupTagCarouselCoordinates} />
        {smallPickupTags.map(({ tag }, index) => {
          if (tag.coordinates.length === 0) return null

          return (
            <PickupTagSmallCards
              key={tag.id}
              tagName={tag.name}
              tagId={tag.id}
              coordinates={tag.coordinates.map((coordinate) => {
                return {
                  href: `/${coordinate.member.user_name}/${coordinate.id}/`,
                  imgUrl: coordinate.image.url_215,
                  imgAlt: getCoordinateImageAlt({
                    nickName: coordinate.member.nick_name,
                  }),
                }
              })}
              imagePriority={index === 0}
            />
          )
        })}
      </div>
      <p className="pr-[15px] pt-8 text-right leading-none">
        <a href="/keyword/" className="text-[13px] font-bold text-blue-400">
          他のトレンドキーワードを見る
        </a>
      </p>

      <h2 className="pl-[15px] pt-[53px] text-[23px] font-bold leading-none">
        人気のユーザー
      </h2>
      {popularUsers.length > 0 && (
        <div className="pl-[25px] pt-5">
          <MemberCarouselList
            members={popularUsers.map((popularUser) => popularUser.member)}
          />
        </div>
      )}
      <p className="pr-[15px] pt-[23px] text-right leading-none">
        <a
          href="/ranking/user/"
          className="text-[13px] font-bold text-blue-400"
        >
          ユーザーランキングを見る
        </a>
      </p>

      <div className="pt-[50px]">
        <GoogleAd elementId={spTopPageAds.middle} />
      </div>

      <div className="pt-[50px]">
        <SpAppBanner
          downloadButtonHref={TOP_PAGE_APP_BANNER_ADJUST_URL}
          onClickDownloadButton={sendGtmEvDownloadButton}
        />
      </div>

      {popularFolders.length > 0 && (
        <div className="flex flex-col gap-y-[30px] px-[15px] pt-[55px]">
          <h2 className="text-[23px] font-bold leading-none">人気のトピック</h2>
          <ul className="flex flex-col gap-y-6">
            {popularFolders.map((folder, index) => {
              const imgUrls = folder.folder_elements
                .map((element) => element.image?.url_215)
                .filter((url) => url !== undefined)
              return (
                <li key={index}>
                  <PopularFolder
                    imgUrls={imgUrls}
                    href={`${folder.member.user_name}/favorite/${folder.id}/`}
                    title={folder.name}
                  />
                </li>
              )
            })}
          </ul>
        </div>
      )}

      <section className="px-[15px] pt-12">
        <h2 className="text-[23px] font-bold leading-none">
          人気のコーディネート
        </h2>
        {coordinateRanking.length > 0 && (
          <div className="pt-[25px]">
            <CoordinateListWithRounded
              coordinates={coordinateRanking.map(
                (coordinate) => coordinate.coordinate
              )}
            />
          </div>
        )}
        <p className="pt-[29px] text-right leading-none">
          <a href="/ranking/" className="text-[13px] font-bold text-blue-400">
            コーディネートランキングを見る
          </a>
        </p>
        <p className="pt-6 text-right leading-none">
          <a
            href="/coordinate/?type_id=2"
            className="text-[13px] font-bold text-blue-400"
          >
            新着コーディネートを見る
          </a>
        </p>
      </section>

      <div className="pt-[50px]">
        <GoogleAd elementId={spTopPageAds.lower} />
      </div>

      <div className="px-[15px] pt-[29px] empty:hidden">
        <Banners />
      </div>
    </div>
  )
}
